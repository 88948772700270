import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { BaseSystemEvent } from "src/app/models/system-event/base-system-event";

@Injectable()
export class SystemEventService {
	protected subject = new Subject<BaseSystemEvent>();

	constructor() {}

	publish(systemEvent: BaseSystemEvent) {
		this.subject.next(systemEvent);
	}

	get events(): Observable<BaseSystemEvent> {
		return this.subject.asObservable();
	}
}
