import { Injectable } from "@angular/core";
import { OnAppInit } from "src/app/models/on-app-init";
import { SystemEventService } from "../system-event/system-event.service";
import { filter, map } from "rxjs/operators";
import { JsonConfigLoaded } from "src/app/models/system-event/json-config-loaded";
import { forkJoin, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { JsonConfigItem } from "src/app/models/json-config/json-config.model";

@Injectable()
export class JsonConfigService implements OnAppInit {
  protected appointments: JsonConfigItem[];
  protected menus: JsonConfigItem[];
  protected inspectionTimes: JsonConfigItem[];
  protected timesEveryFifteen: JsonConfigItem[];
  protected timesEveryThirty: JsonConfigItem[];
  protected calendarMenus: JsonConfigItem[];
  constructor(
    private systemEventService: SystemEventService,
    private httpClient: HttpClient
  ) {
    this.appInit();
  }

  getAppointments(): JsonConfigItem[] {
    return this.appointments;
  }

  getMenus(): JsonConfigItem[] {
    return this.menus;
  }

  getInspectionTimes(): JsonConfigItem[] {
    return this.inspectionTimes;
  }

  getTimesEveryFifteen(): JsonConfigItem[] {
    return this.timesEveryFifteen;
  }

  getTimesEveryThirty(): JsonConfigItem[] {
    return this.timesEveryThirty;
  }

  getCalendarMenus(): JsonConfigItem[] {
    return this.calendarMenus;
  }

  appInit(): void {
    const sub = this.systemEventService.events
      .pipe(filter((events) => events instanceof JsonConfigLoaded))
      .subscribe((_event: JsonConfigLoaded) => {
        console.log("test-json-config")

        this.initAsAsync().subscribe(() => {
          sub.unsubscribe();
        });
      });
  }

  initAsAsync(): Observable<any> {
    const subscribers: Observable<any>[] = [];

    subscribers.push(
      this.loadJsonConfig<JsonConfigItem[]>('appointments.json').pipe(
        map((appointment) => {
          this.appointments = appointment;
        })
      )
    );

    subscribers.push(
      this.loadJsonConfig<JsonConfigItem[]>('actionMenu.json').pipe(
        map((menu) => {
          this.menus = menu;
        })
      )
    );

    subscribers.push(
      this.loadJsonConfig<JsonConfigItem[]>('times.json').pipe(
        map((inspectionTime) => {
          this.inspectionTimes = inspectionTime;
        })
      )
    );

    subscribers.push(
      this.loadJsonConfig<JsonConfigItem[]>('timesEveryFifteen.json').pipe(
        map((timeEveryFifteen) => {
          this.timesEveryFifteen = timeEveryFifteen;
        })
      )
    );

    subscribers.push(
      this.loadJsonConfig<JsonConfigItem[]>('timesEveryThirty.json').pipe(
        map((timeEveryThirty) => {
          this.timesEveryThirty = timeEveryThirty;
        })
      )
    );

    subscribers.push(
      this.loadJsonConfig<JsonConfigItem[]>('calendarMenu.json').pipe(
        map((calendarMenu) => {
          this.calendarMenus = calendarMenu;
        })
      )
    );

    return forkJoin(subscribers);
  }

  loadJsonConfig<T>(jsonFileName: string): Observable<T> {
    const baseUrl = window.location.origin;
    const jsonFilePath = '/assets/configs/';
    return this.loadJsonConfigByAbsolutePath(baseUrl + jsonFilePath + jsonFileName);
  }

  loadJsonConfigByAbsolutePath<T>(jsonPath: string): Observable<T> {
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };
    return this.httpClient.get<T>(jsonPath, options);
  }
}