import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import * as Pako from 'pako';

interface HttpOptions {
  headers: HttpHeaders;
  params?: HttpParams;
  body?: any;
  responseType?: any;
}

interface HttpHeaders {
  [header: string]: string | string[];
}

interface HttpParams {
  [param: string]: string | string[];
}

@Injectable()
export class ProxyService {
  protected isZipped = false;

  constructor(
    protected httpClient: HttpClient
  ) {}

  get<T>(url: string, headers?: HttpHeaders, params?: HttpParams, responseType?: string): Observable<T> {
    const method = 'GET';
    const options = this.buildOptions(headers, params);
    
    if (responseType) {
      options["responseType"] = responseType;
    }
    
    return this.httpClient.get<T>(url, options)
      .pipe(
        tap(res => this.writeLogEnd(method, url, res)),
        catchError(this.handleError<any>(method, null))
      );
  }

  post<T>(url: string, data: any, headers?: HttpHeaders, params?: HttpParams, responseType?: string): Observable<T> {
    const method = 'POST';
    const options = this.buildOptions(headers, params);
    const binaryData = this.binaryData(data);

    if (responseType) {
      options["responseType"] = responseType;
    }

    if (environment.globalConfig.gzip.enabled && this.isZipped) {
      options.headers['Content-Encoding'] = 'gzip';
    }

    return this.httpClient.post<T>(url, binaryData, options)
      .pipe(
        tap(res => this.writeLogEnd(method, url, res)),
        catchError(this.handleError<any>(method, null))
      );
  }

  put<T>(url: string, data: any, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    const method = 'PUT';
    const options = this.buildOptions(headers, params);
    const binaryData = this.binaryData(data);

    if (environment.globalConfig.gzip.enabled && this.isZipped) {
      options.headers['Content-Encoding'] = 'gzip';
    }

    return this.httpClient.put<T>(url, binaryData, options)
      .pipe(
        tap(res => this.writeLogEnd(method, url, res)),
        catchError(this.handleError<any>(method, null))
      );
  }

  delete<T>(url: string, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    const method = 'DELETE';
    const options = this.buildOptions(headers, params);

    return this.httpClient.delete<T>(url, options)
      .pipe(
        tap(res => this.writeLogEnd(method, url, res)),
        catchError(this.handleError<any>(method, null))
      );
  }

  protected buildOptions(headers?: HttpHeaders, params?: HttpParams): HttpOptions {
    const options: HttpOptions = {
      headers: headers ? headers : { 'Content-Type': 'application/json' }
    }

    if (params) { options.params = params; }

    return options;
  }

  protected writeLogEnd(method: string, url: string, response: any): void {
    // console.log(`${method} completed - ${url}`);
    // console.log(response);
  }

  protected handleError<T>(method: string = 'Operation', result?: T) {
    return (error: any): Observable<T> => {
      if (error && error.message) {
        // console.log(`${method} failed: ${error.message}`);
        // console.error(error);
      }

      return of(result as T);
    }
  }

  protected binaryData(data: any) {
    this.isZipped = false;
    if (environment.globalConfig.gzip.enabled && window['enabledGzip' as keyof Window]) {
      let binaryData: ArrayBuffer;
      if (typeof (data) === 'string' && data.length > 100) {
        const zipData = Pako.gzip(data);
        binaryData = zipData.buffer;
        this.isZipped = true;
      } else if (typeof (data) === 'object') {
        const stringData = JSON.stringify(data);
        if (stringData && stringData.length > 100) {
          const zipData = Pako.gzip(stringData);
          binaryData = zipData.buffer;
          this.isZipped = true;
        } else {
          binaryData = data;
        }
      } else {
        binaryData = data;
      }
      return binaryData;
    }
    return data;
  }
}
